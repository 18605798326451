<template>
  <div class="col-12 c g">
    <div class="card card-body">
        <div class="col-12">
            <button class="btn btn-sm btn-primary" @click="seenall()">
                <i class="fa fa-eye"></i>
                تعيين الكل "تمت رؤيتها"
            </button>
            <br><br>
        </div>
        <div class="col-12 table-responsive" style="min-height: 500px">
            <table class="table table-hover table-bordered table-sm">
                <thead>
                    <th>
                        المعلم
                    </th>
                    <th>
                        الطالب
                    </th>
                    <th>
                        الفصل
                    </th>
                    <th>
                        التاريخ
                    </th>
                    <th>
                        نوع المشكلة
                    </th>
                    <th>
                        تكرار المشكلة
                    </th>
                    <th>
                        وصف المشكلة
                    </th>
                    <th>
                        اجراء المعلم
                    </th>
                    <th>
                        الحالة	
                    </th>
                    <th>
                        رد الوكيل
                    </th>
                    <th>
                        خيارات
                    </th>
                </thead>
                <tbody>
                    <tr v-for="item in forwards" :key="item._id">
                        <td>
                            {{ item.teacher_name }}
                        </td>
                        <td>
                            {{ item.student_name }}
                        </td>
                        <td>
                            {{ item.classname }} - {{ item.classroom }}
                        </td>
                        <td>
                            {{ item.date }}
                        </td>
                        <td>
                            {{ item.type }}
                        </td>
                        <td>
                            {{ item.repeat }}
                        </td>
                        <td>
                            {{ item.details }}
                        </td>
                        <td>
                            {{ item.opinion }}
                        </td>
                        <td>
                            <span v-if="item.second_viewed" class="text-success">
                                <i class="fa fa-eye"></i>
                                تمت رؤيتها<br>الرد: {{ item.second_reply }}
                            </span>
                            <span v-if="!item.second_viewed" class="text-danger">
                                <i class="fa fa-clock-o"></i>
                                في الانتظار	
                            </span>
                            <span v-if="item.direct_from_teacher"><br>من المعلم</span>
                        </td>
                        <td>
                            {{ item.response }}
                        </td>
                        <td>
                            <b-dropdown dropleft style="width: 100%"
                                id="dropdown-1"
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                text="خيارات"
                                variant="secondary"
                                size="sm"
                            >
                                <b-dropdown-item @click="seen(item._id)" v-if="!item.second_viewed">
                                    <i class="fa fa-eye"></i>
                                    تمت رؤيتها
                                </b-dropdown-item>
                                <b-dropdown-item @click="reply(item._id, item.second_reply)">
                                    <i class="fa fa-reply"></i>
                                    رد على المشكلة
                                </b-dropdown-item>
                            </b-dropdown>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <b-modal id="message" title="رسالة لولي الامر" hide-footer>
        <div class="form-group">
          <label for="">الرسالة</label>
          <textarea class="form-control" v-model="message" rows="3" @change="sendmessage(itemx)" @keyup="sendmessage(itemx)"></textarea>
        </div>
        <div class="g border">
            {{ finalmessage }}
        </div>
        <div class="row">
            <div class="col-12 col-lg-4 g">
                <button class="btn btn-success btn-block" @click="send('whatsapp')">
                    <i class="fa fa-whatsapp"></i>
                        واتس تلقائي
                </button>
            </div>
            <div class="col-12 col-lg-4 g">
                <button class="btn btn-success btn-block" @click="send('wa')">
                    <i class="fa fa-whatsapp"></i>
                    اداة المتصفح
                </button>
            </div>
            <div class="col-12 col-lg-4 g">
                <button class="btn btn-primary btn-block"  @click="send('sms')">
                    <i class="fa fa-mobile"></i>
                    SMS
                </button>
            </div>
        </div>
    </b-modal>
  </div>
</template>

<script>
import { BModal, VBModal, BFormCheckbox, BDropdown, BDropdownItem } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
    components: {
        BFormCheckbox,
        BModal,
        BDropdown,
        BDropdownItem,
    },
    directives: {
        Ripple,
        "b-modal": VBModal,
    },
    data(){
        return {
            user: JSON.parse(localStorage.getItem('user')),
            forwards: [],
            message: `ولي امر الطالب {name}
تم تسجيل مشكلة لابنكم
وصف المشكلة {details}`,
            phones: [],
            finalmessage: "",
            itemx: {}
        }
    },
    created(){ 
        if(!checkPer("students|forwards2")){
            this.$router.push('/NotPermitted')
            return false;
        }
        this.getFo();
    },
    methods: {
        sendmessage(item){
            this.itemx = item;
            if(item.phones){
                this.phones = item.phones
            }
            this.finalmessage = this.message.replace("{name}", item.student_name).replace("{details}", item.details)
        },
        getFo(){
            var g = this;
            $.post(api + '/user/lessons/forward/second/list', {
                jwt: this.user.jwt
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response)
                }else{
                    g.forwards = r.response;
                }
            }).fail(function(){
            })
        },
        seen(id){
            var g = this;
            $.post(api + '/user/lessons/forward/second/seen', {
                jwt: this.user.jwt,
                id: id
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response)
                }else{
                    g.getFo()
                }
            }).fail(function(){
            })
        },
        seenall(){
            var g = this;
            if(!confirm("متاكد من تعيين الكل انه تمت رؤيتها؟")){return false;}
            $.post(api + '/user/lessons/forward/second/seen-all', {
                jwt: this.user.jwt
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response)
                }else{
                    g.getFo()
                }
            }).fail(function(){
            })
        },
        reply(id, reply){
            var g = this;
            var reply = prompt("الرد", reply ?? "")
            if(reply){
                $.post(api + '/user/lessons/forward/second/reply', {
                    jwt: this.user.jwt,
                    id: id,
                    reply: reply
                }).then(function(r){
                    g.loading = false
                    if(r.status != 100){
                        alert(r.response)
                    }else{
                        g.getFo()
                    }
                }).fail(function(){
                })
            }
        },
    }
}
</script>

<style>
.classroom{
    padding: 15px; 
    border: 2px solid #ddd;
    border-radius: 5px;
}
.classroom:hover{
    background: #fafafa !important;
    cursor: pointer;
    border: 2px solid #333;
}
</style>